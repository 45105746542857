@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dv1fr2');
  src:  url('fonts/icomoon.eot?dv1fr2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dv1fr2') format('truetype'),
    url('fonts/icomoon.woff?dv1fr2') format('woff'),
    url('fonts/icomoon.svg?dv1fr2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-signal-good:before {
  content: "\e600";
  color: #5FD865;
}


.icon-signal-bad:before {
  content: "\e600";
  color: #ff4d4f;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ff4d4f 0%,#ff4d4f 33%,#b8b8b8 33%,#a3a3a3 100%); */
}

.icon-signal-normal:before {
  content: "\e600";
  color: #f8a222;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #f8a222 0%,#f8a222 66%,#b8b8b8 66%,#a3a3a3 100%); */
}
