.flex {
    display: flex;
}
.flex-ac {
    display: flex;
    align-items: center;
}

.flex-jc {
    display: flex;
    justify-content: center;
}
.flex-drc {
    display: flex;
    flex-direction: column;
}

.flex-cc{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-jb {
    display: flex;
    justify-content: space-between;
}

.flex-cb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.cp {
    cursor: pointer;
}

.static {
    position: static;
  }
  
  .fixed {
    position: fixed;
  }
  
  .absolute {
    position: absolute;
  }
  
  .relative {
    position: relative;
  }
  
  .sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  
  /* 超出显示… 1行|2行|3行|删除线|下划线 */
  .text-h {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .text-hs {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
  }
  
  .text-hss {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
  }
  
  .text-d {
    text-decoration: line-through;
  }
  
  .text-u {
    text-decoration: underline;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-justify {
    text-align: justify;
  }
  
  .text-start {
    text-align: start;
  }
  
  .text-end {
    text-align: end;
  }
  
  .z-0 {
    z-index: 0;
  }
  
  .z-10 {
    z-index: 10;
  }
  
  .box-border {
    box-sizing: border-box;
  }
  
  .box-content {
    box-sizing: content-box;
  }
  
  .block {
    display: block;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .inline {
    display: inline;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  
  
  .mt0 {
    margin-top: 0;
  }
  
  .mt1 {
    margin-top: 10px;
  }
  
  .mt2 {
    margin-top: 20px;
  }
  
  .mb0 {
    margin-bottom: 0;
  }
  
  .mb1 {
    margin-bottom: 10px;
  }
  
  .mb2 {
    margin-bottom: 20px;
  }
  
  .ml0 {
    margin-left: 0;
  }
  
  .ml1 {
    margin-left: 10px;
  }
  
  .ml2 {
    margin-left: 20px;
  }
  
  .mr0 {
    margin-right: 0;
  }
  
  .mr1 {
    margin-right: 10px;
  }
  
  .mr2 {
    margin-right: 20px;
  }

  .pt0 {
    padding-top: 0;
  }
  
  .pt1 {
    padding-top: 10px;
  }
  
  .pt2 {
    padding-top: 20px;
  }
  
  .pb0 {
    padding-bottom: 0;
  }
  
  .pb1 {
    padding-bottom: 10px;
  }
  
  .pb2 {
    padding-bottom: 20px;
  }
  
  .pl0 {
    padding-left: 0;
  }
  
  .pl1 {
    padding-left: 10px;
  }
  
  .pl2 {
    padding-left: 20px;
  }

  .pl4 {
    padding-left: 40px;
  }
  
  .pr0 {
    padding-right: 0;
  }
  
  .pr1 {
    padding-right: 10px;
  }
  
  .pr2 {
    padding-right: 20px;
  }
  
  .f18 {
    font-size: 18px;
  }

  .fw-6 {
    font-weight: 600;
  }
  .fw-5 {
    font-weight: 500;
  }
  
  .fw-8 {
    font-weight: 800;
  }
  .ant-drawer, .ant-drawer-content-wrapper {
    z-index: 1001 !important;
  }