#login-panel{
    .login-tr{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: #5143ff transparent transparent transparent;
        position: absolute;
        left: 27px;
    }
    // .ant-input-affix-wrapper > input.ant-input{
    //     padding-left : 10px
    // }

    .ant-form-item-label{
        padding : 0 0 3px
    }

    .ant-form-item{
        margin-bottom: 16px;
    }

    .ant-input{
        font-size: 12px !important;
    }

    // .ant-form-item-has-error {
    //     .ant-form-item-explain, .ant-form-item-extra{
    //         display: none;
    //     }
    // }
}